.form-procedi{
    border: 1px solid rgba(43, 42, 42, 0.205);
    border-radius: 10px;
    background-color: rgba(243, 239, 247, 0.397);
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
    

}
.proce-name label{
    
    margin: 10px 15px 20px 10px;
    font-weight: bold;
   
   
    
}
.proce-name input{
  
    border-radius: 8px;
    width: 200px;
}
.proce-name input:hover{
    background-color: rgba(229, 245, 245, 0.644);
}

.proce-boton{
    display: flex;
    flex-direction: row;
    margin-left: 450px;
}
.proce-boton button{
    width: 90%;
    height: 30px;
    border-radius: 8px;
    background-color: blue;
    color: white;
}
.proce-boton button:hover{
    background-color: rgba(70, 70, 255, 0.632);
}
.bt-actualizar{
    
    
    border: 1px solid ;
    border-radius: 8px;
    color: white;
    background-color: rgb(98, 98, 253);
    font-size: 15px;
    height: 20px;
    margin: 9.5px 6px;
    padding:4.5px 25px;
    text-decoration: none;

}
.bt-actualizar:hover{
   background-color:  rgba(0, 0, 255, 0.632);
}
/* Estilos generales */

/* Tus estilos generales aquí */

/* Estilos para pantallas pequeñas */
@media screen and (max-width: 767px) {
    .form-procedi {
      flex-direction: column;
      align-items: center;
    }
    
    .proce-name input {
      width: 80%;
      max-width: 500px; /* Asegura que el ancho máximo del input sea de 500px */
    }
    
    .proce-boton {
      margin-left: 0;
      margin-top: 10px;
      width: 70%;
      justify-content: center; /* Centra los botones horizontalmente */
    }
    
    .proce-boton button {
      width: 70%;
       /* Asegura que el ancho máximo del botón sea de 90px */
    }
    
    .bt-actualizar {
      width: 70%;
      max-width: 150px; /* Asegura que el ancho máximo del enlace sea de 180px */
      margin: 10px 0;
    }
  }
  
  
 