.vista {
  /* border: 1px solid rgb(125, 120, 120); */
  font-size: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  background-color: #f1f1f186;
  border-radius: 5px;
  width: 95%;
  padding: 15px;
  margin-bottom: 20px;
}
.vista-nombre{

  width: 350px;
}
.vista-cirujano{

  width: 300px;

}
.vista-procedimiento{
 
  width: 250px;
}
.vista-tiempo{
  width: 250px; 
}
.vista-tiempo span{
 
  margin-right: 35px;
}
.vista-tiempo h3{
  margin-left: 15px;
}
.vista-cx{
  
  width: 150px;
}
.vista-hora{

  width:100px;
}
.vista-fecha{
  width: 200px;
  /* margin-right: 25px; */
}
.retro-name {
  
  font-weight: bold;
}

.boton-preparacion {
  background-color: #ffc107;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
}

.boton-procedimiento {
  background-color: #17a3b8b0;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.boton-recuperacion {
  background-color: #28a745;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}
@keyframes estado-letter-animation {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.estado-button {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  overflow: hidden;
}
.estado-preparacion {
  border: 1px solid black;
  width: 110px;
  background-color: rgba(255, 255, 0, 0.564);
}

/* Aplicamos la animación solo a las letras dentro del elemento con la clase .estado-preparacion */
.estado-preparacion span {
  display: inline-block;
 
  /* Aplicamos la animación solo a las letras dentro del elemento con la clase .estado-preparacion */
  animation: estado-letter-animation 4s infinite;
}
@keyframes procedimiento-letter-animation {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.estado-preparacion {
  border: 1px solid black;
  background-color: rgba(255, 255, 0, 0.564);
  
}

.estado-procedimiento {
  border: 1px solid rgb(77, 76, 76);
  background-color: rgb(85, 234, 85);
  width: 110px;
}
.estado-procedimiento span {
  display: inline-block;
  /* Aplicamos la animación solo a las letras dentro del elemento con la clase .estado-procedimiento */
  animation: procedimiento-letter-animation 4s infinite;
}
@keyframes recuperacion-letter-animation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.estado-recuperacion span {
  display: inline-block;
  /* Aplicamos la animación solo a las letras dentro del elemento con la clase .estado-recuperacion */
  animation: recuperacion-letter-animation 4s infinite;
}
.estado-recuperacion {
  border: 1px solid black;
  background-color: rgba(0, 0, 255, 0.459);
  width: 110px;
}
@media screen and (max-width: 875px) {
  .vista {
    background-color: #eef5f52e;
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    
  }

  /* Los elementos tendrán ancho 100% en pantallas pequeñas */
  .vista-nombre, .vista-cirujano, .vista-procedimiento, .vista-cx, .vista-hora, .vista-fecha {
    
    width: 100%;
  }

  /* Ajusta el tamaño de los botones y estados según tus necesidades */
}
@media screen and (max-width: 768px) {
  .vista {
   
    background-color: rgba(255, 255, 255, 0.341);
    flex-direction: column; /* Cambia la dirección de los elementos a columna */
    font-size: 20px;
    margin-bottom: 5px;
  }
  



  /* Los elementos tendrán ancho 100% en pantallas pequeñas */
  .vista-nombre, .vista-cirujano, .vista-procedimiento, .vista-cx, .vista-hora, .vista-fecha {
    color: #050927;
    width: 100%;
  }

  /* Ajusta el tamaño de los botones y estados según tus necesidades */
}
