.contenedor-agenda{
  /* background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 36%, rgba(112,138,157,0.6783088235294117) 100%); */
  /* background: rgb(34,193,195);
background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(24,145,233,0.46551120448179273) 45%);
  height: 1200px;
  
  */
  animation: changeBackground 15s infinite;
  height: 1300px;
  background-size: cover;

  
}
@keyframes changeBackground {
  0% {
    background-image: url("../../assets/frente.jpeg");
  }
  33% {
    background-image: url("../../assets/frente2.jpg");
  }
  66% {
    background-image: url("../../assets/recuperacion.jpeg");
  }
  100% {
    background-image: url("../../assets/salacx.jpeg");
  }
  
}



.containe-padre{

  max-height: 450px;
  display: flex;
  flex-direction: row;
 
}
/* .containe-padre h1{
  margin: 100px;
  margin-right: 700px;
 
  text-transform: inherit;
  
} */
.calen{

  display: flex;
  flex-direction: row;
  /*   

  display: flex;
  padding: 15px;
  height: 200px;
  width: 90% !important;
  height: 125% !important;
  color: #080808; */
  
  .react-calendar {
    width: 350px;
    max-width: 100%;
    height: 400px;
    background: #b4babaa5;
    border: 1px solid #a0a096;
    
}
  .react-calendar__navigation{
    border-radius: 8px;
    
    background-color: #3aace568;
    height: 25%;
  }
  .react-calendar__navigation__label {
    border-radius: 8px;
    
    font-size: 15px;
    display: flex;
    color: black; /* Color del texto de la fecha */
    
  }
  .react-calendar__month-view__days {
    border-radius: 8px;
    background-color: #c7d0d4;
    
  }
  
  
  

}
.vista-logo-boton{
  border: 1px solid;
  display: flex;
  flex-direction: column-reverse;
  max-width: 55%;
  margin-right: 100px;
  margin-left: 0;
  margin-top: 35px;
  .button{
    margin-left: 550px;
  }
 .vist{
 
  width: 150px;
 }
  
}




.button {
  /* display: inline-block; */
  border: 1px solid black;
  max-width: 50px;
  padding: 5px ;
  margin-top: 15px;
  height: 20px;
  background-color: #448bd6;
  color: #080808;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
}

.button:hover {
  background-color: #5d9cb4;
}
.link-button {
  display: inline-block;
  font-size: 15px;
  margin: 15px;
  padding: 6px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  text-decoration: none;
}

.link-button:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 768px) {
  .contenedor-agenda {
    height: auto;
    background: linear-gradient(0deg, rgba(34, 193, 195, 1) 36%, rgba(112, 138, 157, 0.6783088235294117) 100%);
  }

  .containe-padre {
    height: auto;
    
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }

  .calen{
  
    display: flex;
    
    padding: 25px;
    height: 200px;
    width: 90% !important;
    height: 125% !important;
    color: #080808;
    
    .react-calendar {
      width: 350px;
      max-width: 120%;
      height: 380px;
      margin-top: 15px;
      margin-bottom: 15px;
      background: #b4babaa5;
      border: 1px solid #a0a096;
      
  }
    .react-calendar__navigation{
      border-radius: 8px;
      
      background-color: #3aace568;
      height: 25%;
    }
    .react-calendar__navigation__label {
      border-radius: 8px;
      
      font-size: 15px;
      display: flex;
      color: black; /* Color del texto de la fecha */
      
    }
    .react-calendar__month-view__days {
      border-radius: 8px;
      background-color: #c7d0d4;
      
    }
    .vista-logo-boton{
      
      display: flex;
      flex-direction: column-reverse;
      max-width: 55%;
      margin-right: 100px;
      margin-left: 0;
      margin-top: 35px;
      .button{
        margin-left: 550px;
      }
     .vist{
      border: 1px solid red;
      width: 180px;
     }
      
    }
    
    
  
  }
  

  
 
}
