.buscar{
    
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.buscar input{
    height: 25px;
    border: 2px solid black;
    border-radius: 8px;
}
/* Estilos para pantallas mayores a 768px de ancho */
@media screen and (min-width: 768px) {
    .columna {
      width: 50%;
      float: left;
      
    }
  }
  
  /* Estilos para pantallas menores o iguales a 768px de ancho */
  @media screen and (max-width: 768px) {
    .columna {
      width: 100%;
      float: none;
      margin: 0 auto; 
    }
  }