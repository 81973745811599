.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
  
  }
  
  .register-container form {
    border: 1px solid rgb(211, 207, 207);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    
  }
  
  
  .form-group {
   display: flex;
   flex-direction: column;
   margin: 0;
  
  }
label{
  margin: 0px;
  padding: 0;
    width: 320px;

  }

/*   
  button {
    margin-top: 10px;
  }
   */
  .volver-btn {
    display: inline-block;
    
    padding: 8px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .volver-btn:hover {
    background-color: #0056b3;
  }
  
  .volver-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
  