
 .card-detalle-paciente {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 98%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .detail-paciente {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .button-cerrar-x{
    text-decoration: none;
    border-radius: 8px;
    height: 25px;
    width: 25px;
    margin-top: 15px;
    margin-right: 25px;
    background-color: rgb(130, 130, 236);;
    color: rgb(13, 15, 16);
    font-size: 18px;
    text-align: center; /* Alinea el texto en el centro */
  padding: 4px 4px 4px 0; /* Añade una separación alrededor del texto */
  line-height: 1.3;
    
}
.button-cerrar-x:hover{
   background-color: rgb(90, 168, 246);
   border: 1px solid black;
}
  
  .detalle-nombre{
    border:1px solid #cfcccc;
    border-radius: 8px;
    background-color: #eee8e8;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
  }
  .detalle-paciente-id{
    border:1px solid #cfcccc;
    background-color: #eee8e8;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
  }
  .detalle-paciente-proce {
    border:1px solid #cfcccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    background-color: #eee8e8;
  }
  
  .detalle-paciente-id,
  .detalle-paciente-proce {
    margin-top: 10px;
  }
  
  .detalle-nombre span,
  .detalle-paciente-id p,
  .detalle-paciente-proce p {
    flex: 1;
  }
  
  .detalle-nombre span:not(:last-child),
  .detalle-paciente-id p:not(:last-child),
  .detalle-paciente-proce p:not(:last-child) {
    margin-right: 10px;
  }
  .cambiar-estado{
    border: 1px solid rgb(194, 194, 194);
    background-color: antiquewhite;
    border-radius: 8px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;

  }
  .cambiar-estado select{
    border-radius: 5px;
    background-color: rgb(56, 56, 255);
    color: white;
    margin: 5px 5px 5px 5px;
  }
  .cambiar-estado select:hover{
   
    background-color: rgb(219, 219, 237);
    color: rgb(6, 6, 6);
    margin: 5px 5px 5px 5px;
  }

  @media screen and (max-width: 768px) {
    .card-detalle-paciente {
      width: 100%;
      padding: 10px;
    }
    
    .button-cerrar-x {
      margin-right: 10px;
    }
    
    .detalle-nombre,
    .detalle-paciente-id,
    .detalle-paciente-proce {
      flex-direction: column;
      height: auto;
      padding: 10px;
    }
    
    .detalle-nombre span,
    .detalle-paciente-id p,
    .detalle-paciente-proce p {
      margin-bottom: 5px;
    }
  }