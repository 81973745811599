.form-container {
     
    margin: 0 10px;
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .form-name{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
    
  }
  .form-name label{
    flex: 1;
    margin-right: 50px;
  }
  .form-vista{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .form-vista label{
    flex: 1;
    margin-right: 50px;
  }
 
 .form-vista-sala{

  display: flex;
  justify-content: space-between;
  flex-direction: row;
 } 
 .form-vista-sala label{
  flex: 1;
  margin-right: 50px;
}
.form-vista-fecha{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}
.form-vista-fecha label{
  flex: 1;
  margin-right: 50px;
}
  .form-container label {
    display: block;
    margin-bottom: 20px;
  }
  #duracion{
    width: 50px;
  }
  
  .form-container input[type="text"],
  .form-container select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .form-container button[type="submit"] {
    background-color: #4caf50;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-container button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  .form-container button[type="button"] {
    background-color: #f44336;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .form-container button[type="button"]:hover {
    background-color: #d32f2f;
  }
  .botones{
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center; /* Alinear verticalmente los elementos */
    justify-content: center; /* Alinear horizontalmente los elementos */
  }
  .volver-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2cc5e1;
  color: white;
  min-width: 100px; /* Ajusta el tamaño mínimo del botón */
  height: 35px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  }
  
  .volver-button:hover {
    background-color: #2f45d3;
  }
  .duracion {
    border: 1px solid 
    ;
  }
  @media screen and (max-width: 768px) {
    .form-name,
    .form-vista,
    .form-vista-sala,
    .form-vista-fecha {
      flex-direction: column;
      margin-bottom: 20px;
    }
    
    .form-name label,
    .form-vista label,
    .form-vista-sala label,
    .form-vista-fecha label {
      margin-right: 0; /* Elimina el margen derecho */
    }
   
   }
  /* Estilos para pantallas muy pequeñas */
  /* Estilos para pantallas menores de 767px */
@media screen and (max-width: 767px) {
  .form-container {
    width: 100%;
  }
  
  .form-container input[type="text"],
  .form-container select {
    width: 90%;
    margin-bottom: 10px;
  }
  
  .form-name label,
  .form-vista label,
  .form-vista-sala label {
    width: 100%;
    margin-right: 0;
  }
  .form-vista-fecha label{
    width: 100%;
    margin-right: 0;
  }
  .botones {
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }
  
  .volver-button {
    max-width: 25%;
    margin-left: 10px;
    margin-bottom: 10px
  }
}
