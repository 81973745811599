* {
  
  margin: 0;
 
  
}


.navegacion {
   
   
  margin: 0px;
  padding: 10px;
 
}
.navegacion-vista{
  /* background-color: rgb(187, 181, 181); */
  display: flex;
  flex-direction: row;
 
}
.menu {
  margin: 15px;
}
.habitaciones {
  margin: 15px;
}

.procedimientos {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  
}
.procemiento-card{
 
  display: flex ;
  flex-direction: row;
  width: auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  margin-bottom: 20px;
}
.procedimineto-card-delete {
  display: flex;
  align-items: end;
  margin-left: auto;
}

.procedimineto-card-delete a{
  display: inline-block;
  margin: 5px;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
}

.procedimineto-actualizar-btn{
  background-color: rgb(0, 106, 255);
  color: white;
  border: 1px solid black;
}
.procedimiento-delete-btn{
  background-color: rgb(255, 0, 0);
  color: white;
  border: 1px solid black;
}
.procedimineto-card-delete a:hover{
  opacity: 0.8;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ajusta la altura según sea necesario */
}

.vista-section {
  display: flex;
  flex-direction: row;
}

.opciones {
  border: 1px solid rgb(125, 120, 120);
  background-color: #9faebe;
  border-radius: 5px;
  width: 280px;
  height: auto;
}

.espacio {
 
  margin: 0px;
  padding: 15px;
  /* font-size: 30px; */
  width: 80%;

}
.espacio ul{
  border: 1px solid rgba(43, 42, 42, 0.205);
  border-radius: 10px;
}
.espacio ul li a{
  border: 1px solid red;
  font-size: 20px;
  margin:55px;
  
  padding: 0px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.habitacion-card {
  
  height: 50px;
  border-radius: 5px;
  background-color: #ebeff7;
}
.habita-actualizar-btn{
  font-size: 15px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  height: 28px;
  width: 80px;
  text-align: center;
}
.habita-actualizar-btn:hover{
  background-color: #278fffdf;
}
.habita-delete-btn{
  font-size: 15px;
  margin-right: 40px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: #f90000;
  color: white;
  height: 28px;
  width: 80px;
  text-align: center;
}
.habita-delete-btn:hover{
  background-color: #f64a4adf;
}

.cirujanos-list{
  list-style-type: none;
  border: 1px solid gray;
  margin: 5px;
  font-size: 35px;
}


.button-link {
  display: block;
  margin:15px;
  width: 150px;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.button-link:hover {
  background-color: #0056b3;
}

.opciones ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.opciones li {
  margin-bottom: 5px;
}
.opciones li a{
  text-decoration: none;
}
.card-container {
  display: flex;
  flex-direction: column;
  
}

.card {

  display: flex ;
  flex-direction: row;
  width: auto;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  margin-bottom: 20px;
}




.card-delete {

  display: flex;
  align-items: end;
  margin-left: auto;


  
}
.card-delete a{
  display: inline-block;
  margin: 5px;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 16px;
}
.actualizar-btn{
  background-color: rgb(0, 106, 255);
  color: white;
  border: 1px solid black;
}
.eliminar-btn{
  background-color: rgb(255, 0, 0);
  color: white;
  border: 1px solid black;
}
.card-delete a:hover{
  opacity: 0.8;
}


.actualizar-btn {
  color: #1b1c1e;
  cursor: pointer;
  text-decoration: none;
}


.actualizar-btn:hover {
  background-color: #8e9cab;
  color: #070808;
}
.eliminar-btn:hover{
  background-color: #8e9cab;
  color: #070808;
} 
.habitacion-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.habitacion-card p {
  margin: 0;
}

.habitacion-actions {
  display: flex;
}

.habitacion-actions button {
  margin-left: 10px;
}
.paciente {
 
  display: flex;
  flex-direction: column;
  margin: 10px 0 15px 5px;
  
}


.paciente-card{
 border: 1px solid rgb(195, 195, 195);
 border-radius: 8px;
 background-color: #dee2e6;
 height:40px;
 
 padding: 15px;
 display: flex;
 flex-direction: row;
 margin-bottom: 15px;
}
.paciente-nombre{
 
  width:400px;
  font-size: 20px;
}
.card-actions{
display: flex;
  margin-left: 400px;
  font-size: 20px;
}
.card-actions a {
  display: inline-block;
  margin: 5px;
  padding: 8px 12px;
  
  border-radius: 4px;
  
  text-decoration: none;
  font-size: 16px;
}

.paciente-actualizar-btn {
  background-color: rgb(0, 106, 255);
  color: white;
  border: 1px solid black;
}

.paciente-eliminar-btn {
  background-color: rgb(255, 0, 0);
  color: white;
  border: 1px solid black;
}

.card-actions a:hover {
  opacity: 0.8;
}
.paciente-detalle-btn {
  background-color: rgb(255, 119, 0);
  color: white;
  border: 1px solid black;
}
.paciente-detalle-btn:hover{
  background-color: rgba(255, 119, 0, 0.782);
}
/* Estilos para pantallas pequeñas */
@media screen and (max-width: 1200px) {
  .vist {
    margin-right: 0;
  }

  .vista-section {
    flex-direction: column;
  }

  .opciones {
    width: 100%;
  }

  .opciones ul {
    margin-left: 10px;
  }

  .card-container {
    align-items: center;
    
  }
  
  .paciente {
 
    display: flex;
    flex-direction: column;
    margin: 10px 0 15px 5px;
    
  }
  
  
  .paciente-card{
   border: 1px solid rgb(195, 195, 195);
   border-radius: 8px;
   background-color: #dee2e6;
   height:40px;
   width: 100%;
   padding: 15px;
   display: flex;
   flex-direction: row;
   margin-bottom: 15px;
  }
  .paciente-nombre{
   
    width:400px;
    font-size: 20px;
  }

  .card-actions {
    margin-left: auto;
    justify-content: center;
  }

  .card-actions a {
    margin: 5px;
  }

  .containe-padre h1 {
    margin: 50px;
    margin-right: 0;
    text-align: center;
  }
  .habita-actualizar-btn,
  .habita-delete-btn,
  .actualizar-btn,
  .eliminar-btn,
  .paciente-actualizar-btn,
  .paciente-eliminar-btn,
  .paciente-detalle-btn {
    font-size: 12px;
    padding: 6px 10px;
    width: 60px;
  }

  /* Ajustar el tamaño de los botones de la navegación */
  .button-link {
    font-size: 12px;
    padding: 8px 12px;
    width: 100px;
  }

  /* Ajustar el tamaño de los botones dentro de los cards */
  .paciente-card .card-actions a,
  .card-container .card-actions a {
    font-size: 12px;
    padding: 6px 10px;
    width: 50px;
  }
}

/* Agrega aquí más reglas CSS específicas para pantallas pequeñas según tus necesidades */

@media screen and (max-width: 768px) {
  .vist {
    margin-right: 0;
  }

  .vista-section {
    flex-direction: column;
  }

  .opciones {
    width: 100%;
  }

  .opciones ul {
    margin-left: 10px;
  }

  .card-container {
    align-items: center;
    display: block;
  }
  
   .paciente-nombre{
    
     font-size: 15px;
   }
  .paciente-card {
    flex-direction: column;
  }

  .card-actions {
    margin-left: 0;
    justify-content: center;
  }

  .card-actions a {
    margin: 5px;
  }

  .containe-padre h1 {
    margin: 50px;
    margin-right: 0;
    text-align: center;
  }
  .habita-actualizar-btn,
  .habita-delete-btn,
  .actualizar-btn,
  .eliminar-btn,
  .paciente-actualizar-btn,
  .paciente-eliminar-btn,
  .paciente-detalle-btn {
    font-size: 12px;
    padding: 6px 10px;
    width: 60px;
  }

  /* Ajustar el tamaño de los botones de la navegación */
  .button-link {
    font-size: 12px;
    padding: 8px 12px;
    width: 100px;
  }

  /* Ajustar el tamaño de los botones dentro de los cards */
  .paciente-card .card-actions a,
  .card-container .card-actions a {
    font-size: 12px;
    padding: 6px 10px;
    width: 50px;
  }
}

/* Agrega aquí más reglas CSS específicas para pantallas pequeñas según tus necesidades */
