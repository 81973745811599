.nav-agenda{
 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 800px;
    
    
}
.nav-agenda-img{
    padding: 80px;
    margin-left: 50px;
}
.nav-button button{
    
    width: 120px;
}
@media screen and (max-width: 1208px) {
    .nav-agenda {
       padding: 15px;
        margin: 15px 0px 10px 0  ;
        max-width: 40%;
      flex-direction: row; /* Cambia a disposición vertical */
       
      margin-left: 60px;
     
    }
   .nav-agenda-img img{
   
    margin: -80px;
    margin-left: -200px;
    
   }
    .nav-button button {
        border:2px solid;
        font-size: 10px;
        margin:-10px;
        margin-left: 60px;  /* Elimina el margen derecho */
        width: 72%; /* El botón ocupa el ancho completo */
    }
  }
  @media screen and (max-width: 1200px) {
    .nav-agenda {
       padding: 15px;
        margin: 15px 0px 10px 0  ;
     
    }
   .nav-agenda-img img{
   
    margin: -20px;
    margin-left: -30px;
    
   }
    .nav-button button {
        border:2px solid;
        font-size: 10px;
        margin:-10px;
        margin-left: 80px;  /* Elimina el margen derecho */
        width: 75%; /* El botón ocupa el ancho completo */
    }
  }
  @media screen and (max-width: 1000px) {
    .nav-agenda {
       
        margin: 15px 0px 10px 0  ;
     
    }
   .nav-agenda-img img{
   
    margin: -20px;
    margin-left: -100px;
    
   }
    .nav-button button {
        border:2px solid;
        font-size: 10px;
        margin:-10px;
        margin-left: 80px;  /* Elimina el margen derecho */
        width: 75%; /* El botón ocupa el ancho completo */
    }
  }
@media screen and (max-width: 768px ) {
    .nav-agenda {
        
        flex-direction: column; /* Cambia a disposición vertical */
        
        max-width: 250px;
       margin-left: -35px;
     
    }
   .nav-agenda-img img{
  
   
    
   width: 100px;
   height: 80px;
   
   margin-top: 30px;
   margin-bottom: -10px;
   margin-left: -30px;
    
    
   }
   .nav-button{
    margin-top: -60px;
    margin-right: 70px;
    margin-bottom: 30px;
    margin-left: 20px;
   }
    .nav-button button {
        border:2px solid;
        font-size: 9px;
        margin-right: 50px;  /* Elimina el margen derecho */
        width: 65%; /* El botón ocupa el ancho completo */
    }
  }