.nav-agenda{
 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 800px;
    
    
}
.nav-agenda-img{
    padding: 80px;
    margin-left: 50px;
}
.buttom-a {
    border: 2px solid rgb(15, 15, 15);
    border-radius: 5px;
    background-color: rgba(0, 0, 254, 0.588);
    margin-top: 20px;
    height: 18px;
    width: 60px;

    text-align: center;
    
    margin-left: 140px;
}
.buttom-a:hover {
    background-color: rgb(137, 137, 241);
}
.buttom-a a{
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 1208px) {
    .nav-agenda {
       padding: 15px;
        margin: 15px 0px 10px 0  ;
        max-width: 40%;
      flex-direction: row; /* Cambia a disposición vertical */
       
      margin-left: 60px;
     
    }
   .nav-agenda-img img{
   
    margin: -80px;
    margin-left: -200px;
    
   }
    
  }
  @media screen and (max-width: 1200px) {
    .nav-agenda {
       padding: 15px;
        margin: 15px 0px 10px 0  ;
     
    }
   .nav-agenda-img img{
   
    margin: -20px;
    margin-left: -30px;
    
   }
    
  }
  @media screen and (max-width: 1000px) {
    .nav-agenda {
       
        margin: 15px 0px 10px 0  ;
     
    }
   .nav-agenda-img img{
   
    margin: -20px;
    margin-left: -100px;
    
   }
    
  }
@media screen and (max-width: 768px ) {
    .nav-agenda {
        
        flex-direction: column; /* Cambia a disposición vertical */
        
        max-width: 250px;
       margin-left: -35px;
     
    }
   .nav-agenda-img img{
  
   
    
   width: 100px;
   height: 80px;
   
   margin-top: 30px;
   margin-bottom: -10px;
   margin-left: -25px;
    
    
   }
   
   .buttom-a{
    border: 2px solid rgb(15, 15, 15);
    border-radius: 5px;
    background-color: rgba(0, 0, 254, 0.81);
    margin-top: -60px;
    padding: 0px;
    width: 70px;
    text-align: center;
    
    margin-left: 120px;

   }
   .buttom-a a{
    color: white;
    font-size: 13px;
    text-decoration: none;
   }
   .buttom-a:hover{
    background-color: rgb(53, 53, 204);
   }
  }
  @media screen and (max-width: 508px ) {
    .nav-agenda {
        
        flex-direction: column; /* Cambia a disposición vertical */
        
        max-width: 250px;
       margin-left: -35px;
     
    }
   .nav-agenda-img img{
  
   
    
   width: 100px;
   height: 80px;
   
   margin-top: 30px;
   margin-bottom: -10px;
   margin-left: -35px;
    
    
   }
 
   .buttom-a{
    border: 2px solid rgb(15, 15, 15);
    border-radius: 5px;
    background-color: rgba(0, 0, 254, 0.81);
    margin-top: -60px;

    padding: 0px;
    width: 70px;
    text-align: center;
    
    margin-left: 110px;

   }
   .buttom-a a{
    color: white;
    font-size: 13px;
    text-decoration: none;
   }
   .buttom-a:hover{
    background-color: rgb(53, 53, 204);
   }
  }
  