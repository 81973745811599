
.crear-cirujano-form {
    border: 1px solid rgba(43, 42, 42, 0.205);
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px auto;
  }
  
  .crear-cirujano-form label {
    margin-bottom: 5px;
    margin: 10px 15px 20px 10px;
    font-weight: bold;
  }
  
  .crear-cirujano-form input {
    padding: 8px;
    margin: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  


  .button-form-cx {
   
    display: flex; 
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -3px;
    margin-left: 25px;
    gap: 10px;
  }
  
  .button-form-cx button {
    
    height: 33px;
    width: 100px;
    background-color: blue;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    
  }
  
  .button-form-cx button:hover {
    background-color: rgb(42, 31, 255);
  }
  
  .button-form-cx a {
    text-decoration: none;
    width: 50px;
    border-radius: 8px;
    height: 15px;
    padding: 8px;
    background-color: rgb(135, 135, 241);
    border: 1px solid rgba(0, 0, 255, 0.618);
    color: rgb(8, 7, 7);
    font-size: 15px;
    margin: 10px;
  }
  
  .button-form-cx a:hover {
    background-color: rgb(17, 47, 239);
  }
  /* Estilos generales */

/* Tus estilos generales aquí */

/* Estilos para pantallas pequeñas */
/* Estilos para pantallas pequeñas */
@media screen and (max-width: 767px) {
  .crear-cirujano-form {
    flex-direction: column;
    
  }
  
  .button-form-cx {
    flex-direction: column;
    align-items: center;
  }
  
  .button-form-cx button{
    width: 35%;
    padding: 0;
    margin: 0px 0;
    font-size: 10px;
  }
  .button-form-cx a {
    width: 35%;
    padding: 2px 0;
    text-align: center;
    margin: 5px 0;
    font-size: 10px;
  }
}


/* Estilos para pantallas medianas */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .crear-cirujano-form label {
    margin: 5px 10px;
  }
  
  .crear-cirujano-form input {
    width: 80%;
  }
}


