.contenedor-agenda-user{

  animation: changeBackground 15s infinite;
  height: 1300px;
  background-size: cover;
  

}
@keyframes changeBackground {
  0% {
    background-image: url("../../assets/frente.jpeg");
  }
  33% {
    background-image: url("../../assets/frente2.jpg");
  }
  66% {
    background-image: url("../../assets/recuperacion.jpeg");
  }
  100% {
    background-image: url("../../assets/salacx.jpeg");
  }
  
}

.containe-padre{

  display: flex;
  padding: 5px;
  height: 320px;
  flex-direction: row; /* Por defecto, en pantallas grandes */
  justify-content: space-between;
  align-items: flex-start;
}





.calendar-container{
  padding: 15px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 125%;
  transform: scale(0.7);
  transform-origin: top left;
}



.calendar{
  border-radius: 15px;
  margin: 35px;
  padding: 10px;
 
  color: #000000;
}

.vista-card{
  margin: 10px;
  padding: 10px;
}

.vista-card p {
  margin: 15px;
  font-size: 25px; /* Tamaño de fuente de 25px */
  text-align: center; /* Centrar el texto */
}
@media screen and (max-width: 1200px) {
  .contenedor-agenda-user{
    background-image: url('../../assets/frente.jpeg');
    height: 1300px;
    background-size: cover;

  
  }
  .containe-padre {
    /* border: 3px solid black; */
    max-width: 80%;
    height: 650px;
    flex-direction: column; 
    justify-content: center;
    max-width: 80%;

    
    
  }
  
  .calen{
    
    max-width: 60%;
    max-height: 450px;
    
    
  }
 
  .nav-container{
   
   margin-bottom: 15px;
    max-width: 65%;
    height: 250px;
    
  }
 
  
}
@media screen and (max-width: 1109px) {
  .contenedor-agenda-user{
    background-image: url('../../assets/frente.jpeg');
    height: 1500px;
    background-size: cover;

  
  }
  .containe-padre {
    /* border: 3px solid black; */
    max-width: 80%;
    height: 650px;
    flex-direction: column; 
    justify-content: center;
    max-width: 80%;

    
    
  }
  
  .calen{
    
    max-width: 60%;
    max-height: 450px;
    
    
  }
 
  .nav-container{
   
   margin-bottom: 15px;
    max-width: 65%;
    height: 250px;
    
  }
 
  
}
@media screen and (max-width: 767px) {
  .contenedor-agenda-user{
    background: rgb(36,0,0);
    background: linear-gradient(90deg, rgba(36,0,0,0.6278886554621849) 55%, rgba(0,252,237,0.6306897759103641) 94%);
   height: auto;
  }
   .containe-padre {
  
     width: 80%;
     height: 500px;
     flex-direction: column; 
     justify-content: center;
     align-items: center;
     margin-bottom: 25px;
     
   }
   .calen{
 
     flex-direction: column-reverse;
     justify-content: center;
     margin-top: 15px;
     
   }
   .calendar-container{

    margin-top: 25px;
    margin-left: 15px;
   }
   
   
   
   
 }