.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  /* background-color: bisque; */
}
.navbar button {
  width: 120px;
}
.agenda-cx {
  text-align: center;
  color: rgb(14, 13, 13);
  text-decoration: none;

  margin: 25px;
}

.btn-agenda {
  display: inline-block;
}
.btn-agenda a {
  text-decoration: none;
}

.agenda-cx {
  display: inline-block;
  padding: 10px 20px;
  background-color: rgb(135, 135, 214);
  color: rgb(6, 6, 6);
  text-decoration: none;
  border-radius: 5px;
}

.agenda-cx:hover {
  background-color: rgb(94, 94, 128);
  color: aliceblue;
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  

  .btn-agenda {
    display: block;
    text-align: center;
  }

  .agenda-cx {
    display: block;
    margin: 10px auto;
  }
}